import React from 'react';
import Marquee from "react-fast-marquee";
import OptimizedImage from '../../utils/optimizedImage';
import { StaticImage } from 'gatsby-plugin-image';
import { TrustbarStandardWrapper, Heading, ContentWrap, Logo, Section } from './styles/TrustbarStandard.styled';

export const TrustbarStandard = ({ component }) => {

  const {
    heading,
    companies,
    background,
    noTopPadding,
    noBottomPadding
  } = component

  return (
    <TrustbarStandardWrapper background={background} noTopPadding={noTopPadding} noBottomPadding={noBottomPadding}>
      {background === "dark" &&
        <StaticImage className="background" src="./styles/trust-bar-background.png" alt="" />
      }
      <Section>
        {heading && <Heading background={background}>{heading}</Heading>}
        <ContentWrap>
          <Marquee speed={50} gradient={false}>
            {companies && companies.map((company) => (
              <Logo key={company}>
                <OptimizedImage image={company?.logo?.gatsbyImageData} src={company?.logo?.file?.url} alt={company?.logo?.description || "logo"} />
              </Logo>
            ))}
          </Marquee>
        </ContentWrap>
      </Section>
    </TrustbarStandardWrapper>
  );
};

TrustbarStandard.displayName = 'TrustbarStandard';
